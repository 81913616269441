//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import eventBus from "@/plugins/eventBus";
import * as _ from "underscore";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import draggable from "vuedraggable";

am4core.useTheme(am4themes_animated);

export default {
  components: {
    draggable
  },
  data() {
    return {
      selectedTab: 'add',
      dispalyedFunnel: {},
      dispalyedFunnelIndex: 0,
      showChart: true,
      showInnerLoading: false,
      currentVendor: {
        confJson: {
          intents: {
            funnels: []
          }
        }
      },
      currentProject: {
        vendorsList: []
      },
      AllIntentsStats: [],
      onlyIntents: [],
      filtered_intenst: [],
      isAlreadyCalling: false,
      horizontalChart: false,
      funnelDialog: false,
      funnelName: '',
      targetFunnel: {
        funnel: {}
      },
      funnelDialogDelete: false,
      chart_data_group: []
    };
  },
  computed: {
    ...mapGetters({
      currentDate: "getFilterDate",
      currentAIAccount: "getCurrentAIAccount",
      currentUser: "getCurrentUser",
      darkTheme: "getDarkTheme"
    }),
    baseURl() {
      return window.location.href;
    },
    
  },
  methods: {
    ...mapActions({
      loadAIProject: "loadAIProject",
      requestAIVendorList: "requestAIVendorList",
      requestIntentStatsAll: "requestIntentStatsAll",
      updateAIVendor: "updateAIVendor",
      requestConversionsFunnel: "requestConversionsFunnel"
    }),
    availableIntents() {
      let currentVendorIntents = _.reject(
        this.AllIntentsStats,
        intent => intent.vendor !== this.currentVendor.openid
      );
      let onlyIntents = _.pluck(currentVendorIntents, "intent");
      return onlyIntents
    },
    updateVendor(vendor) {
      if (_.has(vendor.confJson, "intents")) {
        if (!Array.isArray(vendor.confJson.intents.funnels)) {
          vendor.confJson.intents.funnels = [];
        }
      } else {
        vendor.confJson.intents = {};
        vendor.confJson.intents.funnels = [];
      }
      this.currentVendor = vendor;
      if(vendor.confJson.intents.funnels.length > 0){
        this.selectedTab =  vendor.confJson.intents.funnels[0].title
        this.dispalyedFunnel =  vendor.confJson.intents.funnels[0]
        this.dispalyedFunnelIndex =  0
      }
      if (this.currentProject.vendorsList.length > 1) {
        if(this.$refs.vendorSelect){
          this.$refs.vendorSelect.hidePopup();
        }
      }
    },
    addAnotherFunnelGroup(){
      setTimeout(() => {
        this.currentVendor.confJson.intents.funnels.push({
          title: this.funnelName,
          horizontalChart: false,
          intents: []
        });
        this.updateCurrentAIVendor();
        this.funnelName = ''
        this.funnelDialog = false
      }, 100);
    },
    addNewItentToFunnel(funnel , findex) {
      this.availableIntents();
      setTimeout(() => {
        _.map(this.currentVendor.confJson.intents.funnels , (group , index) =>{
          if(index === findex){
            group.intents.push(this.onlyIntents[0])
          }
          return group
        })
        this.updateCurrentAIVendor();
      }, 100);
    },
    deleteFunnelGroup(funnel , findex){
      this.targetFunnel = {funnel ,findex}
      this.funnelDialogDelete = true
    },
    confirmDeleteFunnel(){
      this.currentVendor.confJson.intents.funnels = _.reject(this.currentVendor.confJson.intents.funnels,(funnel, findex) => findex === this.targetFunnel.findex);
      this.updateCurrentAIVendor();
      setTimeout(() => {
        if(this.currentVendor.confJson.intents.funnels.length > 0){
        this.selectedTab =  this.currentVendor.confJson.intents.funnels[0].title
        this.dispalyedFunnel =  this.currentVendor.confJson.intents.funnels[0]
        this.dispalyedFunnelIndex =  0
      }
      }, 100);
      this.funnelDialogDelete = false
    },
    deleteFunnel(intent, findex ,  index) {
      console.log(intent ,findex ,  index)
      this.currentVendor.confJson.intents.funnels[findex].intents = _.reject(this.currentVendor.confJson.intents.funnels[findex].intents , (intent , intentIndex) =>  intentIndex === index)
      this.updateCurrentAIVendor();
    },
    updateCurrentAIVendor() {
      const that = this;
      const data = {
        projectId: that.currentProject.id,
        vendorData: that.currentVendor
      };
      that.updateAIVendor(data).finally(() =>{
        that.generateDataForFunnelChart()
      })
    },
    generateFunnelChartsSeries(){
      if(this.horizontalChart === true){
          this.generateHorizontalFunnelChart()
        }else{
          this.generateFunnelChart()
        }
    },
    generateFunnelChart( ) {
      if (this.chart) {
        this.chart.dispose();
      }
      if (this.darkTheme === true) {
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
      }
      let chart = am4core.create(this.$refs.chart, am4charts.SlicedChart);
      // Add data

      chart.data = this.chart_data

      let series = chart.series.push(new am4charts.FunnelSeries());
      series.colors.step = 2;
      series.dataFields.value = "occurance";
      series.dataFields.category = "intent";
      series.alignLabels = true;

      series.labelsContainer.paddingLeft = 15;
      series.labelsContainer.width = 200;


      chart.legend = new am4charts.Legend();
      chart.legend.position = "left";
      chart.legend.valign = "bottom";
      chart.legend.margin(5, 5, 20, 5);
      this.chart = chart
      
    },
    generateHorizontalFunnelChart( ) {
      if (this.chart) {
        this.chart.dispose();
      }
      if (this.darkTheme === true) {
        am4core.useTheme(am4themes_dark);
      } else {
        am4core.unuseTheme(am4themes_dark);
      }

      let chart = am4core.create(this.$refs.chart, am4charts.SlicedChart);

      chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

      // Add data

      chart.data = this.chart_data

      let series = chart.series.push(new am4charts.FunnelSeries());
      series.colors.step = 2;
      series.dataFields.value = "occurance";
      series.dataFields.category = "intent";
      series.alignLabels = true;
      series.orientation = "horizontal";
      series.bottomRatio = 1;

      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      this.chart = chart

    },
    loadIntentStats(data) {
      let that = this;
      that.showInnerLoading = true;
      this.requestIntentStatsAll(data).then((resp) =>{
        this.AllIntentsStats = resp.result;
        this.availableIntents();
      }).finally(() => {
        that.showInnerLoading = false;
      });
    },
    loadCurrentProject(account) {
      let that = this;
      that.showInnerLoading = true;
      this.loadAIProject(account).then(response => {
          let currentProject = response;
          const vendorListArr = currentProject.vendorlist.split("+");
          that.requestAIVendorList({
              projectId: account.projectId,
              list: vendorListArr
            }).then(vendor => {
              currentProject.vendorsList = vendor;
              if (currentProject.vendorsList.length > 0) {
                this.updateVendor(currentProject.vendorsList[0]);
              }
              that.currentProject = currentProject;
              setTimeout(() => {
                if (that.isAlreadyCalling === false) {
                  that.generateDataForFunnelChart();
                }
              }, 1000);
            }).finally(() => {
              const data = {
                aiAccount: that.currentAIAccount.id,
                startdate: that.currentDate.start,
                enddate: that.currentDate.end
              };
              this.loadIntentStats(data);
            });
        }).catch(err => {
          console.log("error ", err);
        });
    },
    generateDataForFunnelChart(){
      let that = this
      that.isAlreadyCalling = true
      that.showInnerLoading = true;
        let Data_for_API = {
          aiAccount: that.currentAIAccount.id,
          startdate: that.currentDate.start,
          enddate: that.currentDate.end,
          intentsSeq: that.dispalyedFunnel.intents.join('|'),
        }
        that.requestConversionsFunnel(Data_for_API).then((respData) =>{
          that.chart_data = respData
        }).finally(() => {
          that.isAlreadyCalling = true
          that.showInnerLoading = false;
          that.generateFunnelChartsSeries()
      })
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end
        };
        this.loadIntentStats(data);
      } else {
        console.log("invalid date");
      }
    },
    onAccountUpdate(account) {
      this.loadCurrentProject({
        projectId: account.id,
        accountId: account.accountId
      });
    },
    isValid(value) {
      if (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      ) {
        return false;
      }
      return true;
    }
  },
  watch: {
    showChart(newVal) {
      const that = this;
      if (newVal == false) {
        if (that.chart) {
          that.chart.dispose();
        }
      }
    },
    currentVendor: {
      deep: true,
      handler() {
        this.generateDataForFunnelChart();
      }
    },
    darkTheme() {
      this.generateDataForFunnelChart();
    },
    horizontalChart(newVal){
      let that = this
      if (that.chart) {
        that.chart.dispose();
      }
      this.generateFunnelChartsSeries()
    },
    selectedTab(){
      this.generateDataForFunnelChart()
    }
  },
  mounted() {
    this.loadCurrentProject({
      projectId: this.currentAIAccount.id,
      accountId: this.currentAIAccount.accountId
    });
  },
  created() {
    eventBus.$on("updateDate", this.onDateUpdate);
    eventBus.$on("updateAIAccount", this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off("updateDate", this.onDateUpdate);
    eventBus.$off("updateAIAccount", this.onAccountUpdate);
  }
};
